import React from "react";
import { Link } from "react-router-dom";

function HomeoneLetestWork() {
  const scrolltop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  // Define internal CSS styles
  const imageHoverStyles = {
    transition: "transform 0.2s ease",
  };

  const imageHoverHoverStyles = {
    transform: "scale(1.1)",
  };

  return (
    <>
      <div className="latest-work pt-120 pb-120">
        <div className="container">
          <div className="row d-flex justify-content-lg-end justify-content-center">
            <div className="col-md-7 text-center">
              <div
                className="section-title wow animate fadeInDown"
                data-wow-duration="0.8s"
                data-wow-delay="0.1s"
              >
                <h2>Latest Work</h2>
              </div>
            </div>
          </div>
          <div className="row g-4 d-flex justify-content-center ">
            <div className="col-lg-5 col-md-8 col-sm-8 order-lg-1 order-0">
              <div
                className="section-card l-work-card wow animate fadeInDown"
                data-wow-duration="0.8s"
                data-wow-delay="0.1s"
              >
                <h2>Look Our Latest Work as Restaurant POS</h2>
                <p style={{ marginBottom: "15px" }}>
                  Introducing our state-of-the-art Restaurant POS System,
                  designed to streamline your operations and enhance the dining
                  experience for both your staff and customers. Say goodbye to
                  manual order-taking and cumbersome payment processing, and
                  embrace the efficiency and convenience of our intuitive POS
                  solution.
                </p>
                <Link
                  to={`${process.env.PUBLIC_URL}/project`}
                  onClick={scrolltop}
                  className="eg-btn hover-btn sectoin-card-btn"
                >
                  <span>
                    All Work
                    <img
                      src={
                        process.env.PUBLIC_URL + "/images/icon/arrow-servc.svg"
                      }
                      alt="images"
                    />
                  </span>
                </Link>
              </div>
            </div>
            <div
              className="col-lg-7 col-md-12 col-sm-8 order-1 wow animate fadeInDown"
              data-wow-duration="0.8s"
              data-wow-delay="0.1s"
                      
            >
              <div className="l-work-item h-100">
                <img
                  src={process.env.PUBLIC_URL + "/images/projects/namdo.png"}
                  alt="images" className="h-100"
                  style={imageHoverStyles}
                  onMouseOver={(e) => e.target.style.transform = "scale(1.1)"}
                  onMouseOut={(e) => e.target.style.transform = "scale(1)"}
                />
              </div>
            </div>
            
            <div
              className="col-lg-4 col-md-6 col-sm-8 order-1 wow animate fadeInDown"
              data-wow-duration="0.8s"
              data-wow-delay="0.1s"
            >
              <div className="l-work-item">
                <img
                  src={process.env.PUBLIC_URL + "/images/projects/dharul.png"}
                  alt="images"
                  style={imageHoverStyles}
                  onMouseOver={(e) => e.target.style.transform = "scale(1.1)"}
                  onMouseOut={(e) => e.target.style.transform = "scale(1)"}
                />
                <div className="work-overlay">
                  <div className="work-title hover-btn">
                    <span />
                    <i className="bi bi-arrow-90deg-right" />
                    <h3>
                      <a href="http://darulhikmatours.com/" target="_blank">
                        Darul Hikma Tours
                      </a>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 col-sm-8 order-1 wow animate fadeInDown"
              data-wow-duration="0.8s"
              data-wow-delay="0.2s"
            >
              <div className="l-work-item">
                <img
                  src={
                    process.env.PUBLIC_URL + "/images/projects/namdosmall.png"
                  }
                  alt="images"
                  style={imageHoverStyles}
                  onMouseOver={(e) => e.target.style.transform = "scale(1.1)"}
                  onMouseOut={(e) => e.target.style.transform = "scale(1)"}
                />
                {/* <div className="work-overlay">
                  <div className="work-title hover-btn">
                    <span />
                    <i className="bi bi-arrow-90deg-right" />
                    <h3>
                      <a href="javascript:;">E-commerce</a>
                    </h3>
                  </div>
                </div> */}
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 col-sm-8 order-1 wow animate fadeInDown"
              data-wow-duration="0.8s"
              data-wow-delay="0.2s"
            >
              <div className="l-work-item">
                <img
                  src={process.env.PUBLIC_URL + "/images/projects/HMS.png"}
                  alt="images"
                  style={imageHoverStyles}
                  onMouseOver={(e) => e.target.style.transform = "scale(1.1)"}
                  onMouseOut={(e) => e.target.style.transform = "scale(1)"}
                />
                {/* <div className="work-overlay">
                  <div className="work-title hover-btn">
                    <span />
                    <i className="bi bi-arrow-90deg-right" />
                    <h3>
                      <a href="javascript:;">HMS</a>
                    </h3>
                  </div>
                </div> */}
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 col-sm-8 order-1 wow animate fadeInDown"
              data-wow-duration="0.8s"
              data-wow-delay="0.1s"
            >
              <div className="l-work-item">
                <img
                  src={process.env.PUBLIC_URL + "/images/projects/pvc.jpg"}
                  alt="images"
                  style={imageHoverStyles}
                  onMouseOver={(e) => e.target.style.transform = "scale(1.1)"}
                  onMouseOut={(e) => e.target.style.transform = "scale(1)"}
                />
                <div className="work-overlay">
                  <div className="work-title hover-btn">
                    <span />
                    <i className="bi bi-arrow-90deg-right" />
                    <h3>
                      <a href="https://pvcqatar.greenhoodtech.co.in/#/" target="_blank">
                       PVC Qatar 
                      </a>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 col-sm-8 order-1 wow animate fadeInDown"
              data-wow-duration="0.8s"
              data-wow-delay="0.1s"
            >
              <div className="l-work-item">
                <img
                  src={process.env.PUBLIC_URL + "/images/projects/fk.jpg"}
                  alt="images"
                  style={imageHoverStyles}
                  onMouseOver={(e) => e.target.style.transform = "scale(1.1)"}
                  onMouseOut={(e) => e.target.style.transform = "scale(1)"}
                />
                {/* <div className="work-overlay">
                  <div className="work-title hover-btn">
                    <span />
                    <i className="bi bi-arrow-90deg-right" />
                    <h3>
                      <a href="http://localhost:3000/#/" target="_blank">
                        Food Kot
                      </a>
                    </h3>
                  </div>
                </div> */}
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6 col-sm-8 order-1 wow animate fadeInDown"
              data-wow-duration="0.8s"
              data-wow-delay="0.1s"
            >
              <div className="l-work-item">
                <img
                  src={process.env.PUBLIC_URL + "/images/projects/tvl.png"}
                  alt="images"
                  style={imageHoverStyles}
                  onMouseOver={(e) => e.target.style.transform = "scale(1.1)"}
                  onMouseOut={(e) => e.target.style.transform = "scale(1)"}
                />
                <div className="work-overlay">
                  <div className="work-title hover-btn">
                    <span />
                    <i className="bi bi-arrow-90deg-right" />
                    <h3>
                      <a href="https://www.tvlfoods.com/#!/" target="_blank">
                        Nellaiyappar Restaurant
                      </a>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            {/* <div
              className="col-lg-4 col-md-6 col-sm-8 order-1 wow animate fadeInDown"
              data-wow-duration="1.5s"
              data-wow-delay="0.7s"
            >
              <div className="l-work-item">
                <img
                  src={process.env.PUBLIC_URL + "/images/projects/cargo.png"}
                  alt="images"
                />
                <div className="work-overlay">
                  <div className="work-title hover-btn">
                    <span />
                    <i className="bi bi-arrow-90deg-right" />
                    <h3>
                      <a
                        href="https://cargointerface.com/"
                        target="_blank"
                      >
                        Cargo
                      </a>
                    </h3>
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div
              className="col-lg-4 col-md-6 col-sm-8 order-1 wow animate fadeInDown"
              data-wow-duration="1.5s"
              data-wow-delay="0.8s"
            >
              <div className="l-work-item">
                <img
                  src={process.env.PUBLIC_URL + "/images/projects/ssau.png"}
                  alt="images"
                />
                <div className="work-overlay">
                  <div className="work-title hover-btn">
                    <span />
                    <i className="bi bi-arrow-90deg-right" />
                    <h3>
                      <a
                      href="https://ssau.in/"
                         
                        target="_blank"
                      >
                        SSAU
                      </a>
                    </h3>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default HomeoneLetestWork;
